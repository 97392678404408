import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "@agricircle/shared";
import { displayErrorMessage } from "@agricircle/shared/redux";
import { selectMode } from "../redux/pasture/selectors";
import { useAsyncApi } from "@agricircle/shared/hooks";
import { useLoadFarms } from "@agricircle/shared/farms/hooks";

export const loadPasture = () => {
    const { profile } = useAuthContext();
    const mode = useSelector(selectMode);

    useLoadFarms(profile?.id, mode === "edit", { extraAttributes: ["map_metadata"] });
};


export const usePastureApi = () => {
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();


    ////////////////// Get timeseries
    const getTimeseries = async (farmId, callback) => {
        const result = await sendRequest("GET", `dora/pasture/${farmId}/timeseries`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to retrieve pasture timeseries", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Get latest
    const getLatest = async (farmId, callback) => {
        const result = await sendRequest("GET", `dora/pasture/${farmId}/latest`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to retrieve pasture latest", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Update timeseries
    const updateTimeseries = async (farmId, confirm, callback) => {
        const strategy = confirm ? "ask" : "all";
        const result = await sendRequest("POST", `dora/pasture/${farmId}/timeseries?strategy=${strategy}`);
        if (result.isError && result.status != 422)
            dispatch(displayErrorMessage("Failed to update timeseries", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Update latest
    const updateLatest = async (farmId, confirm, callback) => {
        const strategy = confirm ? "ask" : "all";
        const result = await sendRequest("POST", `dora/pasture/${farmId}/latest/update?strategy=${strategy}`);
        if (result.isError && result.status !== 422)
            dispatch(displayErrorMessage("Failed to update latest", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Update latest
    const jobStatus = async (jobId, callback) => {
        const result = await sendRequest("GET", `dora/pasture/${jobId}/job`);
        if (result.isError && result.status !== 422)
            dispatch(displayErrorMessage("Failed to fetch job status", result.data));
        else
            callback && callback(result.status);
        return result;
    };

    return { getTimeseries, getLatest, updateTimeseries, updateLatest, jobStatus };
};
